import React, { useEffect, useRef, useState } from 'react';
import UserChat from './UserChat';
import AIChat from './AIChat';
import ChatInput from './ChatInput';
import Loading from './Loading';
import axios from 'axios';
import { io } from 'socket.io-client';
import { isTokenExpired } from '../utils/auth';
import { useAuthContext } from '../contexts/AuthContext';

const socket = io(process.env.REACT_APP_SOCKET_URL || '/', {
  transports: ['polling']
});
//const socket = io();

const ChatWindow = ({ selectedSessionId }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const { logout } = useAuthContext();
  const [currentAIMessage, setCurrentAIMessage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || isTokenExpired(token)) {
      logout();
      return;
    }

    const fetchChatData = async () => {
      setMessages([])
      if (!selectedSessionId) return;
      setLoading(true);
      try {
        const response = await axios.get(`/api/chats/${selectedSessionId}/chats`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data
        setMessages(data);

        const lastMessage = data[data.length -1]
        if(lastMessage && lastMessage.role == 'assistant'){
            setCurrentAIMessage(lastMessage.message)
        }
      } catch (error) {
        console.error('Error fetching chat data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChatData();
  }, [selectedSessionId]);

  useEffect(() => {

    const handleAIMessage = (msg) => {
      setCurrentAIMessage((prev) => prev + msg);
    };

    const handleAIMessageEnd = (msg) => {
      setMessages((prevMessages) =>
        prevMessages.map((message, index) =>
          index === prevMessages.length - 1 && message.role === 'assistant'
            ? { ...message, message: msg }
            : message
        )
      );
      setCurrentAIMessage(msg);
    };

    socket.on('ai message', handleAIMessage);
    socket.on('ai message end', handleAIMessageEnd);

    return () => {
      socket.off('ai message', handleAIMessage);
      socket.off('ai message end', handleAIMessageEnd);
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages,currentAIMessage]);

  const handleSendMessage = (message, sessionId) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { session_id: sessionId, role: 'user', message, chat_date: new Date() },
      { session_id: sessionId, role: 'assistant', message: '', chat_date: new Date() },
    ]);
    setCurrentAIMessage('');
    socket.emit('chat message', { session_id: sessionId, message });
  };

  return (
    <div className="flex-1 flex flex-col">
      {loading && <Loading />}
      <div className="flex-1 overflow-y-auto p-4" ref={chatContainerRef}>
        {messages.map((message, index) => {
          const uniqueKey = `${message.session_id}-${message.chat_date}-${index}`;
          return message.role === 'user' ? (
            <UserChat key={uniqueKey} text={message.message} datetime={new Date(message.chat_date).toString()} />
          ) : (
            <AIChat
            key={uniqueKey}
            text={index === messages.length - 1 ? currentAIMessage : message.message} 
            datetime={new Date(message.chat_date).toString()}
          />
          );
        })}
      </div>
      {selectedSessionId && (
        <ChatInput sessionId={selectedSessionId} onSendMessage={handleSendMessage} />
      )}
    </div>
  );
};

export default ChatWindow;
