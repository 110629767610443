import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import MainLayout from './layouts/MainLayout';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path="/login" element={<AuthRoute component={Login} redirectTo="/chat" />} />
            <Route path="/register" element={<AuthRoute component={Register} redirectTo="/chat" />} />
            <Route path="/chat" element={<ProtectedRoute component={MainLayout} redirectTo="/login" />} />
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

const AuthRoute = ({ component: Component, redirectTo }) => {
  const { isAuthenticated } = useAuthContext();
  return isAuthenticated ? <Navigate to={redirectTo} /> : <Component />;
};

const ProtectedRoute = ({ component: Component, redirectTo }) => {
  const { isAuthenticated } = useAuthContext();
  return isAuthenticated ? <Component /> : <Navigate to={redirectTo} />;
};

export default App;
