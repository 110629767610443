import React, { useState } from 'react';

const CodeCopyBtn = ({ children }) => {
  const [copyOk, setCopyOk] = useState(false);

  const handleClick = () => {
    if (navigator.clipboard) {
      if (
        React.isValidElement(children) &&
        children.props &&
        children.props.children
      ) {
        const textToCopy = children.props.children;
        if (typeof textToCopy === 'string') {
          navigator.clipboard.writeText(textToCopy)
            .then(() => {
              setCopyOk(true);
              setTimeout(() => {
                setCopyOk(false);
              }, 500);
            })
            .catch(err => {
              console.error("Failed to copy text: ", err);
            });
        }
      }
    } else {
      console.error("Clipboard API not supported");
    }
  };

  return (
    <div
      className="absolute right-2 top-[0.5rem] text-white text-[1.5em] cursor-pointer transition-transform duration-300 ease-in-out hover:scale-110 hover:opacity-90"
      onClick={handleClick}
    >
      {copyOk ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
          style={{ color: '#0af20a' }}
        >
          <path d="M9 16.2l-3.5-3.5L4 14l5 5L20 8l-1.5-1.5L9 16.2z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          width="100%" height="100%" 
          className="w-6 h-6"
          style={{ color: '#ddd' }}
        >
          <path d="M7.5 3H14.6C16.8402 3 17.9603 3 18.816 3.43597C19.5686 3.81947 20.1805 4.43139 20.564 5.18404C21 6.03969 21 7.15979 21 9.4V16.5M6.2 21H14.3C15.4201 21 15.9802 21 16.408 20.782C16.7843 20.5903 17.0903 20.2843 17.282 19.908C17.5 19.4802 17.5 18.9201 17.5 17.8V9.7C17.5 8.57989 17.5 8.01984 17.282 7.59202C17.0903 7.21569 16.7843 6.90973 16.408 6.71799C15.9802 6.5 15.4201 6.5 14.3 6.5H6.2C5.0799 6.5 4.51984 6.5 4.09202 6.71799C3.71569 6.90973 3.40973 7.21569 3.21799 7.59202C3 8.01984 3 8.57989 3 9.7V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      )}
    </div>
  );
};

export default CodeCopyBtn
