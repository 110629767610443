import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '../contexts/AuthContext';
import { isTokenExpired } from '../utils/auth';

const Sidebar = ({ sessions, onSelectSession, onDeleteSession, isOpen, toggleSidebar, selectedSessionId, updateSessions  }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [openMenuSessionId, setOpenMenuSessionId] = useState(null);
  const { logout } = useAuthContext();
  const menuRef = useRef(null);
  const handleCreateSession = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || isTokenExpired(token)) {
        logout();
        return;
      }
      const response = await axios.post('/api/sessions', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const newSession = response.data;
      updateSessions(newSession); 
      onSelectSession(newSession.session_id); 
    } catch (error) {
      console.error('Error creating new session:', error);
    }
  };
  const handleDeleteSession = async (session_id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token || isTokenExpired(token)) {
        logout();
        return;
      }
      const response = await axios.delete(`/api/sessions/${session_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 204) {
        onDeleteSession(session_id);
      } else {
        console.error('Failed to delete session');
      }
    } catch (error) {
      console.error('Error deleting session:', error);
    }
  };

  const toggleMenu = (session_id) => {
    setOpenMenuSessionId((prev) => (prev === session_id ? null : session_id));
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenMenuSessionId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const filteredSessions = Array.isArray(sessions) ? sessions.filter((session) =>
    session.title.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  return (
    <div
      className={`fixed inset-y-0 left-0 z-20 bg-gray-200 w-64 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } md:relative md:translate-x-0 transition-transform duration-300 ease-in-out h-full flex flex-col`}
    >
      <div className="px-4 py-2 flex justify-between items-center md:hidden">
        <span className="text-lg font-semibold">Menu</span>
        <button onClick={toggleSidebar} className="text-gray-600">
          ✕
        </button>
      </div>
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full px-3 py-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      />
      <div className="flex-1 overflow-y-auto px-4">
        <ul>
          {filteredSessions.map((session) => (
            <li key={session.session_id} className="mb-2 relative">
              <div
                className={`flex items-center rounded-md shadow transition duration-300 ${
                  selectedSessionId === session.session_id ? 'bg-blue-100' : 'bg-white hover:bg-gray-100'
                }`}
              >
                <button
                  onClick={() => onSelectSession(session.session_id)}
                  className="flex-1 text-left px-3 py-2"
                >
                  <div className={`text-sm ${selectedSessionId === session.session_id ? 'text-blue-800' : 'text-gray-800'}`}>{session.title}</div>
                </button>
                <button
                  onClick={() => toggleMenu(session.session_id)}
                  className="p-2 text-gray-600 hover:text-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6h.01M12 12h.01M12 18h.01" />
                  </svg>
                </button>
              </div>
              {openMenuSessionId === session.session_id && (
                <div ref={menuRef} className="absolute right-0 top-full mt-1 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-20">
                  <button
                    onClick={() => handleDeleteSession(session.session_id)}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    Delete Chat
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-around p-4 border-t border-gray-300">
        <button
          onClick={handleCreateSession} 
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600 transition duration-300"
        >
          New Chat
        </button>
        <button
          onClick={logout}
          className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-300"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;