import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import CodeCopyBtn from './CodeCopyBtn';

const Pre = ({ children, ...props }) => (
  <pre {...props} className="mb-12 shadow-[5px_0px_8px_-4px_rgba(0,0,0,0.5),-5px_0px_8px_-4px_rgba(0,0,0,0.5),15px_-10px_1px_-8px_rgba(0,0,0,0)] relative">
    <CodeCopyBtn>{children}</CodeCopyBtn>
    {children}
  </pre>
);
const areEqual =(prevProps, nextProps) => {
  return prevProps.text === nextProps.text;
};
const AIChat = React.memo (({ text, datetime }) => {
  return (
    <div className="flex justify-start mb-4 w-full">
      <div className="flex items-start space-x-2 w-full">
        <img
          src="/avatars/ai.png"
          alt="AI Avatar"
          className="w-8 h-8 rounded-full"
          width="50"
          height="50"
        />
        <div className="bg-gray-300 text-black p-3 rounded-lg shadow-md w-full max-w-full overflow-hidden">
          <ReactMarkdown
            components={{
              pre: (props) => <Pre {...props} />,
              code({ inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                    wrapLines={true} 
                    wrapLongLines={true}
                    style={vscDarkPlus}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                    customStyle={{
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-all', 
                      overflowWrap: 'break-word',
                      maxWidth: '100%', 
                      overflow: 'auto',
                    }}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={`whitespace-pre-wrap break-words ${className}`}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {text}
          </ReactMarkdown>
          <span className="text-xs text-gray-600">{datetime}</span>
        </div>
      </div>
    </div>
  );
},areEqual);

export default AIChat;