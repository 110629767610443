import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import ChatWindow from '../components/ChatWindow';
import Loading from '../components/Loading';
import axios from 'axios';
import { useAuthContext } from '../contexts/AuthContext';
import {  isTokenExpired } from '../utils/auth';

const MainLayout = () => {
  const [sessions, setSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();

  useEffect(() => {
    const fetchSessions = async () => {
      const token = localStorage.getItem('token');
      if (!token || isTokenExpired(token)) {
        logout();
        return;
      }

      setLoading(true);
      try {
        const response = await axios.get(`/api/sessions`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSessions(response.data);
      } catch (error) {
        console.error('Error fetching sessions:', error);
        logout();
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []); 

  const handleSessionCreated = (newSession) => {
    setSessions((prevSessions) => [newSession, ...prevSessions]);
    setSelectedSessionId(newSession.session_id);
  };

  const handleSessionDeleted = (sessionId) => {
    setSessions((prevSessions) => prevSessions.filter((session) => session.session_id !== sessionId));
    if (selectedSessionId === sessionId) {
      setSelectedSessionId(null);
    }
  };

  return (
    <div className="flex h-screen">
      {loading && <Loading />}
      <button
        className="p-2 bg-blue-500 text-white fixed top-2 left-2 z-30 md:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        ☰
      </button>
      <Sidebar
        sessions={sessions}
        onSelectSession={setSelectedSessionId}
        onDeleteSession={handleSessionDeleted}
        isOpen={isSidebarOpen}
        toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
        selectedSessionId={selectedSessionId}
        updateSessions={handleSessionCreated} 
      />
      <ChatWindow
        selectedSessionId={selectedSessionId}
      />
    </div>
  );
};

export default MainLayout;